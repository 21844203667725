import React, { useState } from "react";
import "./qualification.css";
import { HiOutlineAcademicCap, HiOutlineBriefcase, HiOutlineCalendar } from "react-icons/hi";

// Data for Education and Experience
const data = {
    education: [
        {
            title: "BCA ",
            subtitle: "M.P.E.S's S.D.M College,Honnavar",
            date: "2019-2022",
        },
        {
            title: "MCA ",
            subtitle: "NMIT Bangalore",
            date: "2022-2024",
        },
        {
            title: "UI/UX Design",
            subtitle: "Internshala PGC",
            date: "2024-present",
        },
    ],
    experience: [
        {
            title: "Content Mgmt. - Intern",
            subtitle: "Rebyuu Real estate",
            date: "2021-2022",
        },
        {
            title: "Graphic Designer",
            subtitle: "Freelance - Remote",
            date: "2022",
        },
        {
            title: "Web Developer",
            subtitle: "Freelance - Remote",
            date: "2023 - Present",
        },
    ],
};

const Qualification = () => {
    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    };

    return (
        <section className="qualification section">
            <h2 className="section__title">Qualification</h2>
            <span className="section__subtitle">My Journey</span>

            <div className="qualification__container container">
                <div className="qualification__tabs">
                    <div
                        className={
                            toggleState === 1
                                ? "qualification__button button--flex qualification__active"
                                : "qualification__button button--flex"
                        }
                        onClick={() => toggleTab(1)}
                    >
                        <HiOutlineAcademicCap className="qualification__icon" />
                        Education
                    </div>
                    <div
                        className={
                            toggleState === 2
                                ? "qualification__button button--flex qualification__active"
                                : "qualification__button button--flex"
                        }
                        onClick={() => toggleTab(2)}
                    >
                        <HiOutlineBriefcase className="qualification__icon" />
                        Experience
                    </div>
                </div>

                <div className="qualification__sections">
                    {/* Education Section */}
                    <div
                        className={
                            toggleState === 1
                                ? "qualification__content qualification__content-active"
                                : "qualification__content"
                        }
                    >
                        {data.education.map((item, index) => (
                            <div className="qualification__data" key={index}>
                                {index % 2 === 0 ? (
                                    <>
                                        <div>
                                            <h3 className="qualification__title">{item.title}</h3>
                                            <span className="qualification__subtitle">{item.subtitle}</span>
                                            <div className="qualification__calendar">
                                                <HiOutlineCalendar className="qualification__calendar-icon" />
                                                {item.date}
                                            </div>
                                        </div>
                                        <div>
                                            <span className="qualification__rounder"></span>
                                            <span className="qualification__line"></span>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div></div>
                                        <div>
                                            <span className="qualification__rounder"></span>
                                            <span className="qualification__line"></span>
                                        </div>
                                        <div>
                                            <h3 className="qualification__title">{item.title}</h3>
                                            <span className="qualification__subtitle">{item.subtitle}</span>
                                            <div className="qualification__calendar">
                                                <HiOutlineCalendar className="qualification__calendar-icon" />
                                                {item.date}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>

                    {/* Experience Section */}
                    <div
                        className={
                            toggleState === 2
                                ? "qualification__content qualification__content-active"
                                : "qualification__content"
                        }
                    >
                        {data.experience.map((item, index) => (
                            <div className="qualification__data" key={index}>
                                {index % 2 === 0 ? (
                                    <>
                                        <div>
                                            <h3 className="qualification__title">{item.title}</h3>
                                            <span className="qualification__subtitle">{item.subtitle}</span>
                                            <div className="qualification__calendar">
                                                <HiOutlineCalendar className="qualification__calendar-icon" />
                                                {item.date}
                                            </div>
                                        </div>
                                        <div>
                                            <span className="qualification__rounder"></span>
                                            <span className="qualification__line"></span>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div></div>
                                        <div>
                                            <span className="qualification__rounder"></span>
                                            <span className="qualification__line"></span>
                                        </div>
                                        <div>
                                            <h3 className="qualification__title">{item.title}</h3>
                                            <span className="qualification__subtitle">{item.subtitle}</span>
                                            <div className="qualification__calendar">
                                                <HiOutlineCalendar className="qualification__calendar-icon" />
                                                {item.date}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Qualification;
