import Supreet from "../../assets/Supreet.jpeg";
import Project2 from "../../assets/Project2.jpeg";
import Project3 from "../../assets/Project3.jpeg";
import Project4 from "../../assets/Project4.jpeg";
import Project6 from "../../assets/project5.jpg";
import Project7 from "../../assets/project7.png";
import Perfume from "../../assets/perfume.png";
import Wireframe from "../../assets/wireframe.png";
import Event from "../../assets/event.png";



export const projectsData = [
    

    {
        id: 1,
        image: Perfume,
        title: "Perfume Landing Page",
        category: "Design",
        link:"https://www.figma.com/design/IQ98trEIj428UYT5CBmBX7/Course-1---Final-Project?node-id=0-1&t=4pBbAh8zaBWwGOs2-1",
    },
    {
        id: 2,
        image: Supreet,
        title: "Supreet Souharda Society",
        category: "Website",
        link:"https://supreetsouharda.com",
    },
    {
        id: 2,
        image: Event,
        title: "Event Website",
        category: "Design",
        link:"https://www.figma.com/design/QJmJn4RIC0ph1HG1yzXU1r/Course-3---Final-Project?node-id=36-802&t=CN12iRNPBMXehYLc-1",
    },
    {
        id: 3,
        image: Wireframe,
        title: "Fitness App Wireframe",
        category: "Design",
        link:"https://www.figma.com/design/LOzARbNGidtbQj4jd8O86C/Course-2-Final-Project?node-id=39-1425&t=2NV8PI6SRbKXOWpR-1",
    },
    {
        id: 4,
        image: Project7,
        title: "Shalom Trading",
        category: "Website",
        link:"https://shalomtrader.com",
    },
    {
        id: 5,
        image: Project2,
        title: "Aprameya IT Tech Fest NMIT",
        category: "Website",
        link:"https://aprameya.fun",
    },
    {
        id: 6,
        image: Project3,
        title: "NMIT Website Redesign",
        category: "Design",
        link:"https://www.figma.com/design/XmVrnX8LzxBWb7ubHWBVbK/college-website-nmit-idea?t=OIu4sIUKBo2A3TQn-1",
    },
    {
        id: 7,
        image: Project4,
        title: "Whatsapp Local Sharing UI",
        category: "Design",
        link:"https://www.figma.com/design/XvWJvpgFM73WNE5ROu9v6x/Untitled?node-id=0-1&t=OIu4sIUKBo2A3TQn-1",
    },
    {
        id: 8,
        image: Project6,
        title: "JobShield",
        category: "Website",
        link:"https://jobshield.online",
    },
    
];

export const projectsNav = [
    {
        name: "All",
    },
    {
        name: "Website",
    },
    {
        name: "Design",
    },
];